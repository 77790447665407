<template>
    <section class="section-pedidos-recurrentes custom-scroll overflow-auto" style="height:calc(100vh - 95px)">
        <div class="px-xl-5 px-lg-5 px-md-4 px-sm-2 px-2">
            <cargando v-if="cargando" />
            <div class="d-flex">
                <div class="column-pedidos">
                    <div class="row mx-0 align-items-center">
                        <div class="col pl-0">
                            <p class="f-28 text-general f-600">
                                Pedidos recurrentes
                            </p>
                            <div class="like-bar mt-2" />
                        </div>
                        <el-tooltip placement="bottom" content="Crear pedido recurrente" effect="light">
                            <div class="btn-action-small bg-general text-white" @click="crearPedidoRecurrente">
                                <i class="icon-plus f-18" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="bg-white scroll-list custom-scroll overflow-auto shadow-14 br-t-12 pt-3 px-2 mt-3" style="height:calc(100vh - 183px)">
                        <template v-if="empty">
                            <div class="row mx-0 j-center h-100 a-center">
                                <img :src="funImagenGeneral(50)" class="" width="288" height="288" />
                                <p class="text-center text-general f-17">
                                    No has creado pedidos recurrentes
                                </p>
                            </div>
                        </template>
                        <template v-else>
                            <div v-for="(data, index) in recurrentes" :key="index" class="row mx-0 px-3 mb-3 cr-pointer align-items-center row-pedido" @click="mostrarProductos(data, index), getProductosRecurrentes(data.id_recurrente) ">
                                <div v-if="pedidoActivo != null" :class="`${pedidoActivo.index == index ? 'bg-general' : ''} br-20`" style="width:8px;height:77px;" />
                                <div class="col br-12 py-2 ml-2" :style="pedidoActivo == data ? 'background-color:#F6F9FB;' : ''">
                                    <p class="f-17 f-600 text-general ucfirst">
                                        {{ data.nombre }}
                                    </p>
                                    <div class="row mx-0 mt-2">
                                        <div class="col-auto pl-0 f-17 text-general">
                                            {{ convertMoney( data.valor_total ) }}
                                        </div>
                                        <div class="col-auto f-15 text-general ml-auto">
                                            {{ data.productos_cont }} Productos
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="col space-products px-0 ml-4">
                    <productos-recurrentes :productos-recurrentes="pedidoActivo" :productos-info="productosInfo" @editar="modalEditarPedidoRecurrente" @eliminar="modalEliminarRecurrente" @pedidoAgregado="actualizarConteoProductos" />
                </div>
            </div>
        </div>
        <section-footer class="mt-3" />
        <!-- Partials -->
        <modal ref="modalPedidoRecurrente" titulo="Crear pedido recurrente" no-aceptar adicional="Guardar" @adicional="crearRecurrente()">
            <div class="row mx-0 j-center">
                <div class="col-8">
                    <small class="pl-3 text-general">
                        Nombre del pedido
                    </small>
                    <el-input v-model="nombrePedido" placeholder="Nombre" class="w-100" size="small" />
                </div>
            </div>
        </modal>
        <modal ref="modalEditarPedidoRecurrente" titulo="Editar pedido recurrente" no-aceptar adicional="Guardar" @adicional="editarRecurrente()">
            <div class="row mx-0 j-center">
                <div class="col-8">
                    <small class="pl-3 text-general">
                        Nombre del pedido
                    </small>
                    <el-input v-model="nombrePedido" placeholder="Nombre" class="w-100" size="small" />
                </div>
            </div>
        </modal>
        <modal-confirmar ref="modalEliminarPedido" titulo="Eliminar pedido recurrente " mensaje="¿Desea eliminar el pedido recurrente?" cancelar="cancelar" adicional="eliminar" @adicional="eliminarRecurrente" />
    </section>
</template>

<script>
import Recurrentes from '~/services/pedidos/pedidosRecurrentes'
export default {
    components: {
        productosRecurrentes: () => import('./components/productosRecurrentes')
    },
    data(){
        return {
            cargando: false,
            pedidoActivo: null,
            nombrePedido: '',
            empty: false ,
            widthWindow: 0,
            recurrentes: [],
            id_recurrente: null,
            idxPedido: null,
            productosInfo: []
        }
    },
    created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    mounted(){
        this.listaRecurrentes();
    },
    methods: {
        modalEliminarRecurrente(id_recurrente){
            this.idRecurrente = id_recurrente 
            this.$refs.modalEliminarPedido.toggle();
        },
        crearPedidoRecurrente(){
            this.nombrePedido = ''
            this.$refs.modalPedidoRecurrente.toggle();
        },
        modalEditarPedidoRecurrente(id_recurrente, nombre, index){
            this.nombrePedido = nombre;
            this.idRecurrente = id_recurrente;
            this.idxPedido = index;
            this.$refs.modalEditarPedidoRecurrente.toggle();
        },
        actualizarConteoProductos(id_recurrente, nombre, index){
            this.nombrePedido = nombre;
            this.idRecurrente = id_recurrente;
            this.idxPedido = index;
            this.listaRecurrentes();
        },
        mostrarProductos(data, index){
            this.pedidoActivo = {pedidoActivo: data,
                index: index}
            if(this.widthWindow <= 890 ){
                this.$router.push({name: 'pedidos.recurrentes.responsive', params: {id: 1}});
            }
        },
        handleResize(){
            this.widthWindow = window.innerWidth;
        },
        async crearRecurrente(){
            try {
                const payload = {
                    nombreRecurrente : this.nombrePedido
                }
                const { data } = await Recurrentes.nuevoPedidoRecurrente(payload);
                this.idxPedido = 0;
                this.listaRecurrentes()
                this.$refs.modalPedidoRecurrente.toggle();
                this.nombrePedido = ''
                this.notificacion('Exito', data.mensaje ,'success');

            } catch (e){
                this.errorCatch(e)
            }          
        },
        async editarRecurrente(){
            try {
                const payload = {
                    nombreRecurrente : this.nombrePedido,
                    idRecurrente : this.idRecurrente
                }
                const { data } = await Recurrentes.editarPedidoRecurrente(payload);
                this.listaRecurrentes();
                this.nombrePedido= '';  
                this.notificacion('Exito', data.mensaje ,'success');
                this.$refs.modalEditarPedidoRecurrente.toggle();

            } catch (e){
                this.errorCatch(e)
            }          
        },
        async eliminarRecurrente(){
            try {
                const { data } = await Recurrentes.eliminarPedidoRecurrente(this.idRecurrente);
                this.pedidoActivo = null
                this.listaRecurrentes();
                this.$refs.modalEliminarPedido.toggle();
                this.notificacion('Exito', data.mensaje ,'success');

            } catch (e){
                this.errorCatch(e)
            }          
        },
        async listaRecurrentes(){
            try {
                this.cargando = true;
                const { data } = await Recurrentes.getListaPedidosRecurrentes();
                this.recurrentes = data.pedidosRecurrentes;
                if(this.recurrentes.length == 0){
                    this.empty = true
                    this.pedidoActivo = null
                }
                else if(this.pedidoActivo == null){
                    this.empty = false
                    this.pedidoActivo = null
                }else{
                    this.mostrarProductos(this.recurrentes[this.idxPedido], this.idxPedido)
                    this.empty = false
                }
                if(this.pedidoActivo != null){
                    this.getProductosRecurrentes()
                }
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false;
            }
        },
        async getProductosRecurrentes(item){
            try { 
                if(this.pedidoActivo != null){
                    this.id_recurrente = this.pedidoActivo.pedidoActivo.id_recurrente
                    const { data } = await Recurrentes.getListaProductosRecurrentes(item ?? this.id_recurrente);
                    this.productosInfo = data.productosInfo;
                } 
            } catch (e){
                this.errorCatch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.column-pedidos{
    width: 385px;
    .row-pedido{
        .col {
            &:hover{
                background-color: #F6F9FB;
            }
        }
    }
}

@media (min-width: 300px) and (max-width: 1024px){
    .column-pedidos{
        width: 348px !important;
    }
}

@media (min-width: 300px) and (max-width: 985px){
    .scroll-list{
        height: calc(100vh - 193px) !important;
    }
}

@media (min-width: 300px) and (max-width: 890px){
    .space-products{
        display: none;
    }
    .column-pedidos{
        width: 450px !important;
        .scroll-list{
            height: calc(100vh - 247px) !important;
        }
    }
    section .d-flex{
        justify-content: center;
    }
}
@media (max-width: 768px){
    .section-pedidos-recurrentes{
        height: calc(100vh - 167px) !important;
    }
}
</style>