<template>
    <section class="pt-1 custom-scroll overflow-auto" style="height:calc(100vh - 85px)">
        <div class="px-4 ">
            <cargando v-if="cargando" />
            <div v-if="infoCarrito.cant_productos > 0">
                <div class="row mx-0">
                    <p class="col-12 text-black f-600 f-28">
                        Carrito de compras
                    </p>
                    <div class="like-bar ml-3 mt-1" />
                </div>
                <div class="row mx-0 mt-2">
                    <div class="col-xl-5 co-lg-5 col-md-6 col-sm-12 col-12">
                        <p class="text-general f-24 f-600 mb-3">
                            Productos
                        </p>
                        <div class="row mx-0">
                            <div class="col-12 col-xl-10 px-0 custom-scroll overflow-auto" style="height:calc(100vh - 222px)">
                                <div v-for="data in productos" :key="data.id">
                                    <card-producto-carrito :producto="data" :promocion="data.data_promocion" @eliminarPedido="getInfoProductos" @carritoActualizado="getResumenCarrito" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 co-lg-5 col-md-6 col-sm-12 col-12">
                        <resumen-carrito :info-carrito="infoCarrito" @carritoActualizado="getInfoProductos" />
                    </div>
                </div>
            </div>
            <div v-else class="row h-100 justify-content-center align-items-center">
                <div class=" py-3 px-4 mr-3 mb-3 text-center">
                    <img :src="funImagenGeneral(41)" width="250" style="max-height:100%;" />
                    <div class=" py-3 px-4 mr-3 text-center">
                        <strong>  Tu carrito está vacio </strong>
                        <div>
                            Parece que aún no has añadido nada.
                        </div>
                    </div>
                    <div class="row mx-0 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3 mt-2 mt-2 mb-md-2">
                        <div class="col-12 px-2">
                            <div class="btn-general" @click="$router.push({name:'home.tienda'})">
                                Ir a la Tienda
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section-footer class="mt-3 mt-lg-0" />
    </section>
</template>

<script>
import Carrito from '~/services/carrito/carrito'
export default {
    components: {
        cardProductoCarrito: () => import('./components/cardProductoCarrito'),
        resumenCarrito: () => import('./components/resumenCarrito.vue'),
    },
    data(){
        return {
            cargando: false,
            infoCarrito: {},
            productos: []
        }
    },
    mounted(){
        this.getInfoProductos()
    },
    methods: {
        async getInfoProductos(){
            try {
                this.cargando = true;
                const { data } = await Carrito.getInformacionProductosCarrito()
                this.productos = data.productosInfo
                this.getResumenCarrito()
            } catch (error){
                return this.errorCatch(error)
            } finally {
                this.cargando = false;
            }
        },
        async getResumenCarrito(){
            try {
                const { data } = await Carrito.getInformacionCarrito()
                this.infoCarrito = data
                this.infoCarrito.porcentajePedido = (data.subtotal / data.pedido_minimo * 100)
            } catch (error){
                return this.errorCatch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@media (min-width: 300px) and (max-width: 985px){
    section.custom-scroll.overflow-auto{
        //height: calc(100vh - 150px) !important;
        height: calc(100vh - 113px) !important;
    }
}
@media (max-width: 768px){
    section.custom-scroll.overflow-auto{
        //height: calc(100vh - 150px) !important;
        height: calc(100vh - 170px) !important;
    }
}
</style>