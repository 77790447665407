<template>
    <section class="section-cambios px-4">
        <div class="row mx-0 pt-3">
            <i class="icon-back mt-1 f-20 cr-pointer" @click="irADetalle()" />
            <div class="col-8 px-2">
                <div class="row mx-0 mb-4 pl-xl-3 pl-lg-3 pl-md-2 pl-sm-2 pl-2 title-category">
                    <div class="col-auto px-0 f-28 text-general f-600">
                        Productos con cambios
                        <div class="col-12" />
                        <div class="like-bar mt-1" />
                    </div>
                    <div class="mn-pill ml-2 text-white f-22 bg-black" style="width:52px;height:40px;">
                        {{ cambiosPendientes.length }}
                    </div>
                </div>
                <div class="bg-white br-t-12 pt-3 px-4 custom-scroll overflow-auto" style="height:calc(100vh - 180px)">
                    <div class="row mx-0">
                        <div class="mn-pill bg-black text-white f-22" style="height:33px">
                            Tiempo restante {{ tiempoRestante }}
                        </div>
                        <p class="col-12 text-general px-0 mt-2 f-17">
                            Confirma los cambios antes de finalizar el tiempo, de lo contrario se aceptarán automáticamente
                        </p>
                    </div>
                    <div class="row mx-0 mt-3 mb-5">
                        <div class="col-auto pl-0">
                            <div class="row mx-0">
                                <div v-if="diferencia > 0" class="btn-outline px-3 mr-2" @click="aceptarCambios()">
                                    Aceptar todo
                                </div>
                                <div v-if="diferencia > 0" class="btn-outline px-3" @click="rechazarCambios()">
                                    Eliminar todo
                                </div>
                            </div>
                        </div>
                        <div class="ml-4" style="width:250px">
                            <button v-if="diferencia < 0" type="button" class="btn-general mt-2" @click="$refs.modalAguardar.toggle();">
                                Confirmar cambios (Tiempos fin)
                            </button>
                            <button v-else type="button" class="btn-general" @click="confirmarCambios">
                                Confirmar cambios
                            </button>
                        </div>
                    </div>
                    <div v-for="(data, index) in cambiosPendientes" :key="index" class="row mx-0 mt-3 align-items-center">
                        <div class="col-5 px-0" @click="modalDetalleCambio(data)">
                            <card-producto-pedido :producto="data" :promocion="data.data_promocion" />
                        </div>
                        <div v-if="data.estado != 51 && diferencia > 0" class="col-auto">
                            <button type="button" :class="`btn-aceptar${data.cambioAceptado == true ? '-active' : ''} px-4`" @click="data.cambioAceptado = true">
                                <i class="icon-ok-circled-outline f-20" />
                                Aceptar
                            </button>
                        </div>
                        <div v-if="data.estado != 51 && diferencia > 0" class="col-auto px-0">
                            <button type="button" :class="`btn-eliminar${data.cambioAceptado == false ? '-active' : ''}  px-4`" @click="data.cambioAceptado = false">
                                <i class="icon-cancel f-20" />
                                Eliminar
                            </button>
                        </div>
                        <div v-else-if="data.estado == 51" class="col-auto px-0 text-gris2">
                            <i class="icon-cancel f-20" />
                            Producto  eliminado
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pártials -->
        <modal ref="modalAceptarCambios" titulo="Confirmar cambios" tamano="modal-sm" no-aceptar adicional="Confirmar" @adicional="confirmacionCambios">
            <p class="text-center">
                ¿Desea confirmar estos cambios?
            </p>
        </modal>
        <modal ref="modalPedidoActualizado" titulo="Pedido actualizado" no-cancelar no-adicional>
            <p class="text-center font-omnes text-orange f-22 f-40">
                Genial
            </p>
            <p class="text-center f-17 f-600 text-black">
                ¡Tu pedido ha sido actualizado!
            </p>
            <p class="text-center f-15 text-black">
                Puedes revisarlo en tus pedidos activos <br /> desde el menú principal
            </p>
            <div class="row mx-0 j-center">
                <img :src="funImagenGeneral(25)" style="max-width:100%;height:312px;" />
            </div>
        </modal>
        <modal ref="modalAguardar" titulo="¡Aguarda!" no-adicional>
            <p class="text-center f-15 text-black">
                Tu tiempo para confirmar cambios ha <br /> finalizado y se han confirmado <br /> automáticamente.
            </p>
        </modal>
        <modal-producto ref="modalProducto" :data="detalleCambio" />
    </section>
</template>
<script>
import moment from 'moment'
import Pedidos from '~/services/pedidos/listaPedidos'
export default {
    components: {
        cardProductoPedido: () => import('./components/cardProductoPedido'),
        modalProducto: () => import('./partials/modalDetalleCambio.vue'),
    },
    data(){
        return {
            cambioAceptado: null,
            tiempoCambio: false,
            idPedido: this.$route.params.idPedido,
            cambiosPendientes: [],
            detalleCambio: {producto:{},data_promocion:{}},
            tiempoRestante: null,
            infoPedido: {},
            diferencia: null,
        }
    },
    mounted(){
        this.getCambioProductos()
    },
    methods: {
        confirmarCambios(){
            this.$refs.modalAceptarCambios.toggle();
        },
        modalDetalleCambio(item){        
            if(item.estado != 51){ 
                this.detalleCambio = item
                this.$refs.modalProducto.toggle();
            }
        },
        async confirmacionCambios(){
            try {
                let productosEliminados = _.map(_.filter(this.cambiosPendientes, ['cambioAceptado', false]),'id');

                const payload = {
                    eliminados: productosEliminados
                }

                const {data} = await Pedidos.confirmarCambios(this.idPedido, payload)
                this.$refs.modalAceptarCambios.toggle();
                this.irADetalle()

            } catch (error){
                this.errorCatch(error)
            }
        },
        aceptarORechazarCambios(item, trueOrFalse){
            this.cambiosPendientes[item].cambioAceptado = trueOrFalse
        },
        relojAtras(){
            try {
                this.horaLimite = moment(this.infoPedido.updated_at).add(this.infoPedido.horas_autoconfirmacion, 'minutes')
                this.interval = setInterval(() => {
                    this.now= moment()
                    this.diferencia= moment(this.horaLimite, "DD/MM/YY HH:mm:ss").diff(this.now, "DD/MM/YY HH:mm:ss")
                    this.duracion = moment.duration(this.diferencia)
                    this.tiempoRestante = this.duracion._data.hours + ':' + this.duracion._data.minutes + ':'+this.duracion._data.seconds
                    if(parseInt(this.diferencia) < 0 ){
                        this.tiempoRestante = '00:00:00'
                        clearInterval(this.interval);
                    }
                }, 1000)
            } catch (error){
                return this.errorCatch;
            }
        },
        async getCambioProductos(){
            try {
                const { data } = await Pedidos.getCambiosPendientes(this.idPedido);
                if(data.infoPedido.estado != 11){
                    this.irADetalle()
                    return
                }
                this.cambiosPendientes = data.productosCambios
                this.infoPedido = data.infoPedido
                this.relojAtras()
            } catch (error){
                this.errorCatch(error)
            }
        },
        irADetalle(){
            this.$router.push({name:'pedidos.ver',params:{idPedido:this.idPedido}})
        },
        aceptarCambios(){
            this.cambiosPendientes.forEach(item => {
                item.cambioAceptado = true
            });
        },
        rechazarCambios(){
            this.cambiosPendientes.forEach(item => {
                if(item.estado != 51){
                    item.cambioAceptado = false
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.btn-aceptar{
    height: 36px;
    background-color: #ffffff;
    border-radius: 12px;
    color: #000000;
    color: #637381;
    border: 1px solid #DFE4E8;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
     &:focus{
        outline: none !important;
    }
    &-active{
        @extend .btn-aceptar;
        color: #29D884 !important;
        background-color: #EEFCF5 !important;
        border: 1px solid #29D884 !important;
    }
}
.btn-eliminar{
    height: 36px;
    background-color: #ffffff;
    border-radius: 12px;
    color: #637381;
    border: 1px solid #DFE4E8;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
     &:focus{
        outline: none !important;
    }
    &-active{
        @extend .btn-eliminar;
        color: #FF5158 !important;
        background-color: #FFF1F2 !important;
        border: 1px solid #FF5158 !important;
    }
}
</style>
