<template>
    <section class="pt-4 overflow-auto custom-scroll" style="height:calc(100vh - 87px)">
        <div class="px-xl-5 px-lg-5 px-md-4 px-sm-2 px-2">
            <cargando v-if="cargando" />
            <div class="row mx-0 align-items-center pl-xl-3 pl-lg-3 pl-md-2 pl-sm-2 pl-2 title-category">
                <div class="col-xl-5 col-lg-5 col-md-7 col-sm col px-0">
                    <p class="f-28 text-general f-600">
                        Mis pedidos
                    </p>
                    <div class="like-bar mb-2 mt-3" />
                </div>
                <button type="button" class="btn-outline btn-pedidos-recurrentes px-3 text-general" @click="$router.push({name: 'pedidos.recurrentes'})">
                    Pedidos recurrentes
                </button>
                <div class="btn-action-outline-small ml-3 button-responsive" @click="$router.push({name: 'pedidos.recurrentes'})">
                    <i class="icon-calendar f-15 text-general" />
                </div>
            </div>
            <template v-if="!pedidosActivos.length && !pedidosTerminados.length">
                <div class="d-middle-center" style="width:100%;height:55vh;">
                    <div class="col-auto">
                        <div class="row mx-0 j-center">
                            <img :src="funImagenGeneral(50)" width="180" height="180" />
                        </div>
                        <p class="text-center mt-3">
                            Aun no tienes pedidos registrados
                        </p>
                        <div class="row mx-0 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3 mt-2 mt-2 mb-md-2">
                            <div class="col-12 px-2">
                                <div class="btn-general" @click="$router.push({name:'home.tienda'})">
                                    Ir a la Tienda
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row mx-0">
                    <div class="col-auto px-xl-4 px-lg-4 px-md-0 px-sm-0 px-0" />
                    <div class="col px-0">
                        <div class="row mx-0">
                            <div class="col-xl-6 col-lg-8 col-md-8 col-sm-10 col-12 px-0">
                                <div class="row mx-0 a-center justify-content-between pr-1 my-3 bg-fondo2 br-20 text-general border" style="height:35px;">
                                    <p class="col-auto f-15 f-400">
                                        Pedidos en proceso
                                    </p>
                                    <div class="bg-black rounded-circle text-white f-14 text-center" style="width:20px;height:20px;">
                                        {{ pedidosActivos.length }}
                                    </div>
                                </div>
                                <div v-for="(ped, p) in pedidosActivos" :key="p" class="bg-white br-10 p-3 mb-3 cr-pointer" @click="$router.push({name: 'pedidos.ver', params: { idPedido: ped.id }})">
                                    <div class="row mx-0 pb-2 border-bottom align-items-center">
                                        <div class="col-auto px-0 d-flex">
                                            <i :class="iconosPedidos(ped.estado)" />
                                            <span class="ml-1" v-text="`${textosPedidos(ped.estado)}`" />
                                        </div>
                                        <div class="col-auto ml-auto px-0">
                                            <span class="f-600 text-general">
                                                {{ formatNumero(ped.valor_final) }} 
                                            </span>
                                            ({{ ped.productos_cant }})
                                        </div>
                                    </div>
                                    <div class="row mx-0 mt-3 align-items-center">
                                        <img :src="ped.imagen_leechero" class="obj-cover border br-4" width="46" height="46" />
                                        <div class="col f-14 px-2">
                                            <p class="text-gris2">
                                                {{ ped.leechero }}
                                            </p>
                                            <p v-if="ped.entrega_fecha" class="text-gris2 mt-1">
                                                {{ formatearFecha( ped.entrega_fecha, formatoDestino='DD MMMM') }} {{ ped.hora }}
                                            </p>
                                            <p v-else class="text-gris2 mt-1">
                                                No programado
                                            </p>
                                        </div>
                                        <el-badge v-if="!_.includes([3,31,32,33,34], ped.estado)" :value="ped.cant_mensajes > 0 ? ped.cant_mensajes : ''" class="mr-2">
                                            <i class="icon-chat f-17 text-gris2 cr-pointer" />
                                        </el-badge>
                                    </div>
                                    <div class="row mx-0 pt-2 border-top mt-3 align-items-center">
                                        <div class="col-auto px-0 d-flex">
                                            <i :class="`f-20 icon-${ ped.estado == 12 ? 'ok-circled-outline text-green': ped.estado == 110 ? 'pencil-outline  text-black' : ped.estado == 11 ? 'triangle-alert  text-orange' : ped.estado == 31 ? 'alert-circle-outline text-red' : ''}`" />
                                        </div>
                                        <div class="col tres-puntos px-2 mt-1" :class="`${ped.estado == 12 ? 'text-green': ped.estado == 110 ? 'text-black' : ped.estado == 11 ? 'text-orange' : ped.estado == 31 ? 'text-red' : ''}`">
                                            {{ descripcionPedidos(ped.estado) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-auto px-xl-4 px-lg-4 px-md-0 px-sm-0 px-0" />
                    <div class="col px-0">
                        <div class="row mx-0">
                            <div class="col-xl-6 col-lg-8 col-md-8 col-sm-10 col-12 px-0">
                                <div class=" mx-0 j-center">
                                    <div class="col-10 f-18 f-600 py-3">
                                        Pedidos finalizados
                                    </div>
                                </div> 
                                <div v-for="(data, p) in pedidosTerminados" :key="p" class="section-deuda mb-3">
                                    <div class="row mx-0 a-center justify-content-between pr-1 my-3 bg-fondo2 br-20 text-general border" style="height:35px;">
                                        <p class="col-auto f-15 f-400">
                                            {{ data.fecha }}
                                        </p>
                                        <div class="bg-black rounded-circle text-white f-14 text-center" style="width:20px;height:20px;">
                                            {{ data.pedidosContador }}
                                        </div>
                                    </div>
                                    <div v-for="(ped, index) in data.entregas" :key="index" class="bg-white br-10 p-3 mb-3 cr-pointer ">
                                        <div class="row mx-0 pb-2 border-bottom align-items-center" @click="$router.push({name: 'pedidos.ver', params: { idPedido: ped.id }})">
                                            <div class="col-auto px-0 d-flex">
                                                <i :class="iconosPedidos(ped.estado)" />
                                                <span class="ml-1" v-text="`${textosPedidos(ped.estado)}`" />
                                            </div>
                                            <div class="col-auto ml-auto px-0">
                                                <span class="f-600 text-general">
                                                    {{ formatNumero(ped.valor_final) }}
                                                </span>
                                                ({{ ped.productos_cant }}) 
                                            </div>
                                        </div>
                                        <div class="row mx-0 mt-3 align-items-center" @click="$router.push({name: 'pedidos.ver', params: { idPedido: ped.id }})">
                                            <img :src="ped.imagen_leechero" class="obj-cover border br-4" width="46" height="46" />
                                            <div class="col f-14 px-2">
                                                <p class="text-gris2">
                                                    {{ ped.leechero }}
                                                </p>
                                                <p v-if="ped.entrega_fecha" class="text-gris2 mt-1">
                                                    {{ formatearFecha( ped.entrega_fecha, formatoDestino='DD MMMM') }} {{ ped.hora }} 
                                                </p>
                                                <p v-else class="text-gris2 mt-1">
                                                    No programado
                                                </p>
                                            </div>
                                            <el-badge v-if="!_.includes([3,31,32,33,34], ped.estado)" :value="ped.cant_mensajes > 0 ? ped.cant_mensajes : ''" class="mr-2">
                                                <i class="icon-chat f-17 text-gris2 cr-pointer" />
                                            </el-badge>
                                        </div>
                                        <div v-if="ped.estado == 33" class="row mx-0 pt-2 border-top mt-3 align-items-center">
                                            <div class="col-auto px-0 d-flex">
                                                <i class="f-20 icon-suport text-black" />
                                            </div>
                                            <div class="col tres-puntos px-2 mt-1">
                                                Soporte en proceso
                                            </div>
                                        </div>
                                        <div v-else-if="ped.calificacion == null && ped.estado != 25 && ped.estado != 21 && ped.estado != 22 && ped.estado != 23 && ped.estado != 24 && ped.estado != 26 && ped.estado != 27 " class="row mx-0 pt-2 border-top mt-3 align-items-center" @click="calificarProducto(ped.id)">
                                            <div class="col-auto px-0 d-flex">
                                                <i class="f-20 icon-star-empty text-black" />
                                            </div>
                                            <div class="col tres-puntos px-2 mt-1">
                                                Calificar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="masPedidos == true" class="btn col-auto px-1 text-center text-purple  f-19 mb-4" @click="listar()">
                                    Vér más pedidos
                                    <i class="icon-right-open mt-1 f-17 text-purple mr-2" /> 
                                </div>
                                <div v-else class="btn col-auto px-1 text-center text-purple  f-19 mb-4 ">
                                    Ya no tienes más pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <section-footer class="mt-3 mt-lg-0" />
        <!-- Partials -->
        <modal-calificaciones ref="calificacionesProductos" @calificado="calificacionExitosa" />
    </section>
</template>

<script>
import Pedidos from '~/services/pedidos/listaPedidos'
export default {
    components: {
        modalCalificaciones: () => import('./partials/modalCalificaciones')
    },
    data(){
        return{
            cargando: false,
            page: 1,
            pedidosActivos: [],
            pedidosTerminados: [],
            masPedidos: true,
        }
    },
    mounted(){
        this.listaPedidosActivos();
        this.listaPedidosTerminados();
    },
    methods: {
        iconosPedidos(state){
            switch (state){
            case 1:
                return 'icon-order'
            case 2: 
                return 'icon-ok-circled-outline'
            case 5: 
                return 'icon-pencil-outline'
            case 3: 
                return 'icon-truck-outline'
            case 201: 
                return 'icon-cart-arrow-down'
            case 4:
            case 202:
            case 31:
            case 32:
            case 33:
            case 34:
                return 'icon-order-packed'
            case 110:
            case 11:
            case 12:
                return 'icon-pencil-outline'
            case 25: 
                return 'icon-cancel-circled-outline'
            case 21:
            case 22:
            case 23:
            case 24:
            case 26:
            case 27:
                return 'icon-playlist-remove'
            }
        },
        textosPedidos(state){
            switch (state){
            case 1: 
                return 'Creado'
            case 2:
                return 'Confirmado'
            case 3: 
                return 'Enviado'
            case 4:
            case 31:
            case 32:
            case 33:
            case 34:
                return 'Recibido'
            case 110:
                return 'Editando'
            case 201: 
                return 'Alistando'
            case 202:
                return 'Empacado'
            case 110:
                return 'Editando el pedido'
            case 11:
            case 12:
                return 'Editado'
            case 25: 
                return 'Rechazado'
            case 26:
            case 27:
                return 'Cancelado'
            }
        },
        descripcionPedidos(state){
            switch (state){
            case 31:
                return 'Reclamo en proceso'
            case 110:
                return 'Editando el pedido'
            case 11:
                return 'Cambios pendientes por aceptar'
            case 12:
                return 'Cambios del pedido aceptados'
            }
        },
        async listaPedidosActivos(){
            try {
                this.cargando = true;
                const { data } = await Pedidos.getListaPedidosActivos();
                this.pedidosActivos = data.pedidosActivos;
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false;
            }
        },
        calificacionExitosa(id){
            this.pedidosTerminados.forEach(element => {
                Object.entries(element.entregas).forEach(([key, item]) => {
                    if(item.id == id){
                        item.calificacion = 1;
                    }
                })
            });
        },
        async listaPedidosTerminados(){
            try {
                this.cargando = true;
                this.loading = true
                const { data } = await Pedidos.getListaPedidosFinalizados(this.page);
                if(data.pedidosFinalizados.data.length){
                    this.pedidosTerminados.push(...data.pedidosFinalizados.data)
                    //this.loading = false
                }else{
                    this.masPedidos = false
                }

            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false;
            }   
        },
        calificarProducto(item){
            this.$refs.calificacionesProductos.toggle(item);
        },
        listar(){
            this.page += 1
            this.listaPedidosTerminados()
        }
    }
}
</script>
<style lang="scss" scoped>
.button-responsive{
    display: none;
}
@media (min-width: 300px) and (max-width: 985px){
    section{
        height: calc(100vh - 113px) !important;
    }
    .btn-pedidos-recurrentes{
        display: none;
    }
    .button-responsive{
        display: flex;
    }
}
@media (max-width: 768px){
    section{
        height: calc(100vh - 167px) !important;
    }
}
</style>