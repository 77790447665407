<template>
    <section class="overflow-auto scroll-none pt-4" style="height:calc(100vh - 75px)">
        <div class="px-4  px-lg-5" style="min-height:90vh">
            <div class="row mx-0 mb-3">
                <div class="col-auto">
                    <p class="f-30 text-general f-500">
                        Ofertas especiales
                    </p>
                    <div class="like-bar" />
                </div>
            </div>
            <div class="row mx-0 my-3">
                <div class="col-auto">
                    <img :src="promocion.imagen ? promocion.imagen :'/img/no-imagenes/promociones.png'" width="440" height="180px" class="img-promo br-12 border" />
                </div>
                <div class="col-12 col-lg-5 mt-4 mt-lg-0">
                    <div class="mb-2">
                        <p class="f-30 text-general f-500 ucfirst"> {{ promocion.nombre }} </p>
                        <div class="like-bar" />
                    </div>
                    <p class="text-general f-13 f-500"> {{ promocion.descripcion }} </p>
                    <div>
                        <i v-show="promocion.tipo != 6" class="text-general2 f-13 f-500">
                            Vigencia: Del {{ promocion.inicio | helper-fecha('DD MMMM YYYY') }} al {{ promocion.fin | helper-fecha('DD MMMM YYYY') }}
                        </i>
                    </div>
                </div>
            </div>
            <div class="row mx-0 py-2 mt-5">
                <div v-for="(data,key) in productos" :key="key">
                    <card-producto :producto="data" :promocion="data.data_promocion" class="mx-xl-4 mx-lg-3 mx-md-3 mx-sm-2 mx-2" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Promociones from '~/services/promociones/promociones'
export default {
    data(){
        return {
            promocion: {},
            productos: [],
        }
    },
    computed: {
        idPromocion(){
            return this.$route.params.idPromocion
        }
    },
    mounted(){
        this.getPromocion();
    },
    methods: {
        async getPromocion(){
            try {
                const { data } = await Promociones.getPromociones(this.idPromocion);
                this.promocion = data?.promocion;
                this.getProductosPromocion();
            } catch(e){
                this.errorCatch(e)
            }
        },
        async getProductosPromocion(){
            try {
                const params = {
                    idTienda: this.promocion.id_tienda
                };
                const { data } = await Promociones.getProductosPromociones(this.idPromocion, params);
                if(!data.exito){
                    this.notificacion('Upss',`${data.mensaje}`,'warning')
                    this.$router.push({name:'home.tienda'})
                    return
                }
                this.productos = data.productos;
            } catch(e){
                this.errorCatch(e)
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.img-promo{
	width: 100%;
	height: auto;
	max-height: 160px;
}
</style>