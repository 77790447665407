import axios from 'axios';

const API = 'pedidos/recurrentes';

const ENDPOINTS = {
    getListaProductosRecurrentes(idRecurrente){
        return axios.get(`${API}/${idRecurrente}/productos`)
    },
    getListaPedidosRecurrentes(){
        return axios.get(`${API}/lista`)
    },
    getProductosBusqueda(params){
        return axios.get(`${API}/buscar`, { params })
    },
    anadirProductoAlPedido(payload){
        return axios.post(`${API}/agregar-producto`, payload)
    },
    nuevoPedidoRecurrente(payload){
        return axios.post(`${API}/crear`, payload)
    },
    anadirRecurrentesalCarrito(idRecurrente, payload){
        return axios.post(`${API}/${idRecurrente}/carrito/agregar-productos`, payload)
    },
    editarPedidoRecurrente(payload){
        return axios.put(`${API}/editar`, payload)
    },
    eliminarPedidoRecurrente(idRecurrente){
        return axios.delete(`${API}/${idRecurrente}/eliminar`)
    },
    eliminarProductoDeRecurrente(idProducto, idRecurrente){
        return axios.delete(`${API}/productos/${idProducto}/eliminar/${idRecurrente}`)
    },
    getNotificaciones(idRecurrente){
        return axios.get(`${API}/${idRecurrente}/notificaciones`)
    },
    postNotificacionRecurrente(model){
        return axios.post(`${API}/notificacion/save`, model)
    },
    deleteNotificacionRecurrente(idRecurrente, dia){
        return axios.delete(`${API}/${idRecurrente}/notificacion/${dia}/delete`)
    }

};

export default ENDPOINTS;