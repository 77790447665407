<template>
    <section class="mx-3 mt-2 border box-content">
        <div class="row mx-0" style="height:calc(100vh - 87px)">
            <div class="col-4 custom-scroll overflow-auto pt-2" style="height:calc(100% - 0px)">
                <div v-for="(data, d) in 6" :key="data" class="row mx-0 mb-3 cr-pointer" @click="promocion_Activa = d">
                    <div :class="`${ promocion_Activa == d ? 'bg-general' : ''}  br-20`" style="height:173px;width:8px;" />
                    <div class="col px-2">
                        <img :src="url_image" class="w-100 obj-cover br-20" height="133" />
                        <div class="row mx-0 a-center mt-2 pr-3">
                            <i class="icon-calendar-clock text-general2 f-22" />
                            <p class="col-auto text-general f-15">
                                20 jun. - 10 Jul.
                            </p>
                            <span class="f-15 f-600 text-general pr-2 ml-auto">Desc.</span>
                            <div class="bg-black br-20 px-2 text-white">
                                <i class="icon-package-variant-closed" />
                                4
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col border-left px-0">
                <div class="row mx-0 a-center border-bottom" style="height:50px;">
                    <div class="col-auto f-18 text-general f-600">
                        Descuento fijo
                    </div>
                    <div class="col-4 ml-auto">
                        <el-input v-model="buscar_producto" class="w-100 br-20" placeholder="Buscar producto" />
                    </div>
                </div>
                <div class="custom-scroll overflow-auto px-3" style="height:calc(100vh - 137px)">
                    <p class="f-17 text-general">Condiciones:</p>
                    <p class="f-14 text-general mt-1">Cientos de Productos para tu hogar con Precios Increíbles en un solo lugar. ¡Compra Aquí! Comprar aquí es elegir garantía, confianza, calidad y excelentes precios. ¡Compra Ya!</p>
                    <div class="row mx-0 j-center mt-2">
                        <div v-for="data in 10" :key="data" class="col-5 mx-2 mb-3 px-0 border-bottom">
                            <card-producto />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            buscar_producto: '',
            promocion_Activa: null,
            url_image: 'https://www.pallomaro.com/wp-content/uploads/2018/09/Gran-promocion-pallomaro-02-02.png'
        }
    }
}
</script>
<style lang="css" scoped>
.box-content{
    border-radius: 12px 12px 0px 0px;
}
</style>