<template>
    <section class="px-xl-5 px-lg-4 px-md-2 px-sm-2 px-2 pt-4 overflow-auto custom-scroll" style="height:calc(100vh - 87px)">
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <i class="icon-back mt-1 f-20 cr-pointer" @click="$router.push({name:'pedidos'})" />
            <div class="col px-0">
                <div class="row mx-0 mb-3 pl-xl-3 pl-lg-3 pl-md-2 pl-sm-2 pl-2 align-items-center title-category">
                    <div class="col px-0 f-28 text-general f-600">
                        Pedido
                        <div class="col-12" />
                        <div class="like-bar mt-3" />
                    </div>
                    <div v-if="!_.includes([31,32,33,34], pedido.estado)" :class="`mn-pill ${pedido.mensajes > 0 ? 'bg-orange text-white' : 'bg-white text-gris2'}  shadow-0D mr-2 cr-pointer`" style="height:32px" @click="goChat">
                        <i class="icon-chat f-20" />
                        <span v-if="pedido.mensajes > 0" class="mx-2">
                            {{ pedido.mensajes }}
                        </span>
                    </div>
                    <el-dropdown :hide-on-click="true" @command="eventAccion">
                        <div class="border br-5 p-1">
                            <i class="icon-dots-vertical" />
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="1">
                                Volver a pedir
                            </el-dropdown-item>
                            <el-dropdown-item v-show="!estadosNoCancelar.includes(pedido.estado)" :command="2">
                                Cancelar pedido
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="row mx-0">
                    <div class="col-xl col-lg col-md-12 col-sm-12 col-12 p-2 px-4 bg-white shadow-14 br-8">
                        <detalle-pedido :actualizar-detalle="actualizarDetalle" @infoPedido="setPedido" />
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 p-2 ml-xl-4 ml-lg-4 ml-md-0 ml-sm-0 ml-0 mt-xl-0 mt-lg-0 mt-md-4 bg-white shadow-14 br-8">
                        <div class="row mx-0 mb-3 pl-xl-3 pl-lg-3 pl-md-2 pl-sm-2 pl-2 title-category">
                            <div class="col px-0 f-28 text-general f-600">
                                Productos
                                <div class="col-12" />
                                <div class="like-bar mt-1" />
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div v-for="(data, index) in listaProductos" :key="index" class="col-11">
                                <card-producto-pedido :producto="data" :promocion="data.data_promocion" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-cancelar-pedido ref="modalCancelarPedido" @update="updateDellate" @goChatPedido="goChat()" />
        <modal ref="modalAnadirCarrito" titulo="Añadir al carrito" no-aceptar adicional="Añadir" @adicional="repetirPedido">
            <div class="row mx-0 j-center">
                <div class="col-auto f-15">
                    ¿Desea añadir todos los productos al carrito?
                </div>
            </div>
        </modal>
        <modal-chat-pedido ref="modalChat" titulo="Chat del pedido" @update="pedido.mensajes = 0" />
    </section>
</template>

<script>
import Pedidos from '~/services/pedidos/listaPedidos'
export default {
    components: {
        detallePedido: () => import('./components/detallePedido'),
        modalCancelarPedido: () => import('./partials/modalCancelarPedido'),
        cardProductoPedido: () => import('./components/cardProductoPedido')
    },
    data(){
        return {
            cargando: false,
            actualizarDetalle: false,
            pedido: {
                estado: null,
                mensajes: 0,
            },
            widthWindow: 0,
            cantMensajes: 0,
            listaProductos: [],
            estadosNoCancelar: [26, 27, 4, 31, 32, 33, 34],
        }
    },
    computed:{
        idPedido(){
            return Number(this.$route.params.idPedido)
        }
    },
    mounted(){
        this.productosPedido();
    },
    methods: {
        anadirCarrito(){
            this.$refs.modalAnadirCarrito.toggle();
        },
        cancelarPedido(){
            this.$refs.modalCancelarPedido.toggle(this.pedido);
        },
        async repetirPedido(){
            try {
                const { data } = await Pedidos.anadirProductosAlCarrito(this.idPedido);
                this.notificacion('Exito', data.mensaje, 'success')
                this.$refs.modalAnadirCarrito.toggle();

                this.$usuario.cant_productos_carrito = this.listaProductos.length;
            } catch (error){
                return this.errorCatch(error)
            }
        },
        async productosPedido(){
            try {
                this.cargando = true;
                const { data } = await Pedidos.getListaProductos(this.idPedido)
                this.listaProductos = data.productos 
            } catch (error){
                return this.errorCatch(error)
            } finally{
                this.cargando = false;
            }
        },
        setPedido(data){
            this.pedido = data;
        },
        eventAccion(item){
            switch (item){
            case 1:
                this.anadirCarrito()
                break;
            case 2:
                this.cancelarPedido()
                break;
            
            default:
                break;
            }
        },
        updateDellate(){
            this.actualizarDetalle = !this.actualizarDetalle;
        },
        goChat(){
            // El estado se refiere al momento del chat, en este caso los estados diferente de 31, 32, 33 y 34 serían momento 1, pedido.
            let object = { idPedido: this.idPedido, estado: 1 };
            this.$refs.modalChat.toggle(object);
        },
    },
}
</script>
