import axios from 'axios';

const API = 'cliente/pedidos';

const ENDPOINTS = {
    getListaPedidosActivos(){
        return axios.get(`${API}/lista-vigentes`)
    },
    getListaPedidosFinalizados(page){
        return axios.get(`${API}/${page}/lista-finalizados`)
    },
    getCambiosPendientes(idPedido){
        return axios.get(`${API}/cambios-pendientes/${idPedido}/productos`)
    },
    getListaProductos(idPedido){
        return axios.get(`${API}/productos/${idPedido}/lista`)
    },
    anadirProductosAlCarrito(idPedido){
        return axios.post(`${API}/${idPedido}/anadir-productos/carrito`)
    },
    getDetallePedido(idPedido){
        return axios.get(`${API}/${idPedido}/pedido-detalle`)
    },
    confirmarCambios(idPedido, payload){
        return axios.put(`${API}/${idPedido}/confirmar-cambios`, payload)
    },


};

export default ENDPOINTS;