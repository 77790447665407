<template>
	<section>
		<div v-if="productosRecurrentes != null">
			<div v-if="productosRecurrentes == null" class="row mx-0 align-items-center mb-3 py-2">
				<div class="col tres-puntos text-general f-20 f-600">
					Pedidos recurrentes
				</div>
			</div>
			<div v-else class="row mx-0 align-items-center mb-3 py-2">
				<div class="col tres-puntos text-general f-20 f-600 ucfirst">
					{{ productosRecurrentes.pedidoActivo.nombre }}
				</div>
				<el-tooltip placement="bottom" content="Notificaciones" effect="light">
					<div class="btn-action-outline-small ml-auto m-1" @click="toGoCalendary">
						<i class="icon-calendar text-general f-15" />
					</div>
				</el-tooltip>
				<el-tooltip placement="bottom" content="Añadir producto" effect="light">
					<div class="btn-action-outline-small ml-auto" @click="anadirProducto()">
						<i class="icon-plus text-general f-15" />
					</div>
				</el-tooltip>
				<button type="button" class="btn-general px-3 m-3" @click="anadirCarrito">
					Añadir al Carrito
				</button>
				<el-popover ref="popoverOptions" placement="bottom" effect="light">
					<div class="row mx-0 cr-pointer p-2 f-14 item-popover" @click="editarNombre()">
						Editar nombre
					</div>
					<div class="row mx-0 cr-pointer p-2 f-14 item-popover" @click="eliminarRecurrente()">
						Eliminar
					</div>
					<div slot="reference" class="btn-action-outline-small ml-2">
						<i class="icon-dots-vertical f-15 text-general" />
					</div>
				</el-popover>
			</div>
			<div class="space-products custom-scroll overflow-auto w-100" style="height:calc(100vh - 202px)">
				<div class="row mx-0">
					<template v-if="productosRecurrentes == null || productosRecurrentes.pedidoActivo.productos_cont == 0">
						<div class="col-12 py-3">
							<div class="row mx-0 j-center">
								<div class="col-auto">
									<img :src="funImagenGeneral(51)" width="288" height="288" />
								</div>
								<p class="col-8 mt-3 text-center text-general f-17 f-500">
									No has añadido productos a este <br /> pedido recurrente
								</p>
								<div class="col-6 mt-3">
									<button type="button" class="btn-general w-100" @click="anadirProducto()">
										Añadir producto
									</button>
								</div>
							</div>
						</div>
					</template>
					<template v-else>
						<div v-for="data in productosInfo" :key="data.id"
							class="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12">
							<card-agregar-producto :producto="data" @eliminarPedido="eliminarProductoDeRecurrente" />
						</div>
					</template>
				</div>
			</div>
			<!-- Partials -->
			<modal-anadir-producto ref="modalAnadirProducto" @pedidoAgregado="pedidoAgregado" />
			<modal-pedidos-recurrentes ref="modalPedidosRecurrentes" />
			<modal ref="modalAnadirCarrito" titulo="Añadir al carrito" no-aceptar adicional="Añadir"
				@adicional="anadirRecurrentesCarrito">
				<div class="row mx-0 j-center">
					<div class="col-auto f-15">
						¿Desea añadir todos los productos al carrito?
					</div>
				</div>
			</modal>
		</div>
	</section>
</template>

<script>
import Recurrentes from '~/services/pedidos/pedidosRecurrentes'
export default {
	components: {
		modalAnadirProducto: () => import('../partials/modalAnadirProducto'),
		modalPedidosRecurrentes: () => import('../partials/modalPedidosRecurrentes.vue')
	},
	props: {
		productosRecurrentes: {
			type: Object,
			default: null,
		},
		productosInfo: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			empty: false,
			productosRecurrentesInfo: [],
			dataCantidades: []
		}
	},
	methods: {
		anadirCarrito() {
			this.$refs.modalAnadirCarrito.toggle();
		},
		anadirProducto() {
			this.$refs.modalAnadirProducto.toggle(this.productosRecurrentes.pedidoActivo.id_recurrente);
		},
		pedidoAgregado() {
			this.$emit("pedidoAgregado", this.productosRecurrentes.pedidoActivo.id_recurrente, this.productosRecurrentes.pedidoActivo.nombre, this.productosRecurrentes.index);
		},
		editarNombre() {
			this.$refs.popoverOptions.doToggle()
			this.$emit("editar", this.productosRecurrentes.pedidoActivo.id_recurrente, this.productosRecurrentes.pedidoActivo.nombre, this.productosRecurrentes.index);
		},
		eliminarRecurrente() {
			this.$refs.popoverOptions.doToggle()
			this.$emit("eliminar", this.productosRecurrentes.pedidoActivo.id_recurrente);
		},
		async eliminarProductoDeRecurrente(idProducto) {
			try {
				const { data } = await Recurrentes.eliminarProductoDeRecurrente(idProducto, this.productosRecurrentes.pedidoActivo.id_recurrente);
				this.notificacion('Exito', data.mensaje, 'success');
				this.$emit("pedidoAgregado", this.productosRecurrentes.pedidoActivo.id_recurrente, this.productosRecurrentes.pedidoActivo.nombre, this.productosRecurrentes.index)
			} catch (error) {
				return this.errorCatch(error)
			}
		},
		async anadirRecurrentesCarrito() {
			try {
				this.productosInfo.forEach(item => {
					let data = {
						id_producto: item.id_producto,
						cantidad_producto: item.cantidadProducto
					}
					this.dataCantidades.push(data)
				});
				const payload = { dataCantidades: this.dataCantidades }

				const { data } = await Recurrentes.anadirRecurrentesalCarrito(this.productosRecurrentes.pedidoActivo.id_recurrente, payload);
				this.$refs.modalAnadirCarrito.toggle();
				this.notificacion('Exito', data.mensaje, 'success');
				this.dataCantidades = [];
				this.$usuario.cant_productos_carrito = data.data;
			} catch (error) {
				return this.errorCatch(error)
			}
		},

		toGoCalendary() {
			this.$refs.modalPedidosRecurrentes.toggle(this.productosRecurrentes.pedidoActivo.id_recurrente);
		},
	}
}
</script>
<style lang="scss" scoped>
@media (min-width: 300px) and (max-width: 780px) {
	section {
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.item-popover:hover {
	background-color: #F6F9FB;
}
</style>