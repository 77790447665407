<template>
    <section class="px-xl-4 px-lg-4 px-md-0 px-sm-0 px-0 pt-4 pb-3 custom-scroll overflow-auto" style="height:calc(100vh - 85px)">
        <cargando v-if="cargando" />
        <div class="row mx-0 mb-4">
            <p class="col-12 text-black f-600 f-28">
                Tu pedido
            </p>
            <div class="like-bar ml-3 mt-1" />
        </div>
        <div class="row mx-0">
            <div class="col-xl col-lg col-md-12  col-sm-12 col-12">
                <p class="text-general pl-3 f-24 f-600">
                    Direccion de entrega
                </p>
                <div class="box-direction p-3">
                    <div class="row mx-0">
                        <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
                            <label class="text-general f-12 pl-3">Dirección</label>
                            <direcciones ref="componentDirecciones" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mt-4">
                            <label class="text-general f-12 pl-3">Instrucciones de entrega</label>
                            <el-input v-model="instrucciones" type="textarea" class="br-12" :rows="3" placeholder="Menciona tus instrucciones de entrega..." />
                        </div>
                    </div>
                </div>
                <div class="box-delivery border-red f-500 p-3 mt-4">
                    <div class="row mx-0">
                        <div class="col-xl col-lg col-md col-sm-12 col-12 px-2">
                            <p v-html="formatearTextoHtml(parametros.horarios_texto)" />
                        </div>
                    </div>
                </div>
                <template v-if="parametros.horarios_uso === 1">
                    <p class="text-general pl-3 f-24 f-600 mt-4">
                        Elige el día y hora de entrega
                    </p>
                    <div class="box-delivery p-3">
                        <div class="row mx-0">
                            <div class="col-xl col-lg col-md col-sm-12 col-12 px-2">
                                <div class="row mx-0" style="gap: 10px;">
                                    <div v-for="(d, key) in arrayDias"
                                         :key="key"
                                         class="box-shedule d-flex flex-column justify-content-center cr-pointer px-2"
                                         :class="diaActivo == key ? 'bg-green text-white' : 'border text-general2'"
                                         @click="setFechaEntrega(d,key)"
                                    >
                                        <p class="f-14 f-500 tres-puntos">{{ key }}</p>
                                        <p class="f-12 ucfirst">{{ d.fecha | helper-fecha('MMM D') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl col-lg col-md col-sm-12 col-12 px-2">
                                <div v-for="(h,index) in horarios" :key="index"
                                     :class="`row mx-0 br-8 px-2 ${h.disabled ? 'disabled-div' : ''} row-hours${entregaHorario == h.value ? '-active' : ''} mb-2 cr-pointer`"
                                     @click="entregaHorario = h.value"
                                >
                                    <div :class="`check-mn${entregaHorario == h.value ? '-active' : ''}`">
                                        <div class="inside" />
                                    </div>
                                    <div class="col px-2 tres-puntos">
                                        <span class="f-500 f-15">{{ h.inicio }}</span> a <span class="f-500">{{ h.fin }}</span>
                                    </div>
                                </div>
                                <div v-show="horarios.length == 0" class="border br-12 py-2">
                                    <p class="text-general2 f-15 f-600 text-center">
                                        Sin entregas para este día
                                    </p>
                                    <p class="text-general2 text-center f-14">
                                        Por favor selecciona otro.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <p class="text-general pl-3 f-24 f-600 mt-5">
                    Forma de pago
                </p>
                <div v-if="pagoEfectivo" class="box-forma-pago p-3">
                    <div class="row mx-0">
                        <div v-for="(m,key) in metodosPago" :key="key" class="col-auto my-1">
                            <el-radio v-model="formaPago" :label="m.nombre" @change="setMetodoPago(m)" />
                        </div>
                    </div>
                </div>
                <div v-else class="box-forma-pago p-3">
                    Se descontará de nomina
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div v-if="convenio != null">
                    <p class="text-general f-24 f-600 mt-xl-0 mt-lg-0 mt-md-4 mt-sm-4 mt-4">
                        Convenio de nómina
                    </p>
                    <div class="box-convenio p-3">
                        <div class="row mx-0 mb-2">
                            <img :src="convenio.convenio_logo" class="br-4 obj-cover border" width="46" height="46" />
                            <div class="col px-2">
                                <p class="text-general f-600 f-15">
                                    {{ convenio.convenio }}
                                </p>
                                <div class="d-middle" :class="`${convenio.activo ? 'text-green' : 'text-orange'}`">
                                    <i class="f-18" :class="`${convenio.activo ? 'icon-ok-circled-outline' : 'icon-alert-circle-outline'}`" />
                                    <span class="f-14 f-600">{{ convenio.convenio_estado }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 mt-2">
                            <el-checkbox v-model="pagoEfectivo" :true-label="1" :false-label="0" :disabled="convenio.efectivo ? true : false" class="check-green" />
                            <div class="col px-2 f-15 text-general f-500">
                                Pagar de contado al recibir el pedido
                            </div>
                        </div>
                    </div>
                </div>
                <resumen-carrito :info-carrito="infoCarrito" :cargando="cargandoPedido" @carritoActualizado="getResumenCarrito" @realizarPedido="realizarPedido" />
            </div>
        </div>
        <modalAdvertencia ref="modalAdvertencia" :mensaje="mensajeAlerta" :text-boton="botonAlerta" @accion="accionUsuario" />
    </section>
</template>

<script>
import Carrito from '~/services/carrito/carrito'
import Pedido from '~/services/pedidos/pedidos'
export default {
    components: {
        resumenCarrito: () => import('./components/resumenCarrito.vue'),
        modalAdvertencia: () => import('./partials/modalAdvertencia.vue')
    },
    data(){
        return {
            cargando: false,
            cargandoPedido: false,
            instrucciones: '',
            infoCarrito: {},
            
            arrayDias:[],
            horarios:[],
            diaActivo:'',
            entregaFecha:null,
            entregaHorario:null,
            metodosPago:[],
            formaPago:null,
            convenio:{},
            pagoEfectivo:1,

            form:{
                formaPago:null,
                idMetodoPago:null
            },
            mensajeAlerta:'',
            botonAlerta:'',
            parametros: {},
        }
    },
    mounted(){
        this.getResumenCarrito()
        this.getInfoCheckout()
    },
    methods: {
        async getInfoCheckout(){
            try {
                this.cargando = true;
                const {data} = await Carrito.getInfoCheckout()
                this.parametros = data.parametros;
                this.arrayDias = data.horarios
                this.metodosPago = data.metodosPago,
                this.convenio = data.convenio
                if(this.convenio){
                    if(this.convenio.efectivo){
                        this.pagoEfectivo = 1
                    }else{
                        this.pagoEfectivo = 0
                        this.form.formaPago = 5
                    }
                }

            } catch (error){
                this.errorCatch(error)
            } finally{
                this.cargando = false;
            }
        },
        verCupon(){
            this.$refs.modalCupon.toggle();
        },
        reclamarRecompensa(){
            this.$refs.modalCanjear.toggle();
        },
        async realizarPedido(){
            try {
                this.cargandoPedido = true

                if(this.form.formaPago == null || this.form.formaPago == ''){
                    this.notificacion('Advertencia','Por favor seleccione un metodo de pago','warning')
                    return
                }
                if(parseInt(this.parametros.horarios_uso) === 1 && this.entregaFecha == null || this.entregaFecha == ''){
                    this.notificacion('Advertencia','Por favor seleccione una fecha para la entrega de su pedido','warning')
                    return
                }
                if(parseInt(this.parametros.horarios_uso) === 1 && this.entregaHorario == null || this.entregaHorario == ''){
                    this.notificacion('Advertencia','Por favor seleccione un horario para la entrega de su pedido','warning')
                    return
                }

                const payload = {
                    instrucciones: this.instrucciones,
                    entregaFecha:this.entregaFecha,
                    entregaHorario: this.entregaHorario,
                    formaPago: this.form.formaPago,
                    idMetodoPago: this.form.idMetodoPago,
                    idConvenio: this.convenio ? this.convenio.id_convenio : null
                }
                
                const {data} = await Pedido.realizarPedido(payload)
                switch (data.code){
                case 1:
                    this.mensajeAlerta = "Por favor agregue una dirección de entrega."
                    this.botonAlerta = "Agregar Dirección"
                    this.$refs.modalAdvertencia.toggle()
                    return  
                case 2:
                    this.mensajeAlerta = "No tenemos cobertura en tu area, por favor selecciona otra direccón de entrega."
                    this.botonAlerta = "Cambiar Dirección"
                    this.$refs.modalAdvertencia.toggle()
                    return  
                case 3:
                    this.mensajeAlerta = "No cumples con el pedido minimo, por favor agrega mas productos a tu carrito"
                    this.botonAlerta = "Seguir Comprando"  
                    this.$refs.modalAdvertencia.toggle()
                    return    
                default:
                    break;
                }

                this.$usuario.cant_productos_carrito = 0;
                this.$router.push({name: 'carrito-compras.pedido-creado',params:{data:data.data}});


            } catch (error){
                this.errorCatch(error)
            } finally {
                this.cargandoPedido = false
            }
        },
        async getResumenCarrito(){
            try {
                this.cargando = true;
                const { data } = await Carrito.getInformacionCarrito()
                this.infoCarrito = data
                this.infoCarrito.porcentajePedido = (data.val_total / data.pedido_minimo * 100)
            } catch (error){
                return this.errorCatch(error)
            } finally {
                this.cargando = false;
            }
        },
        setFechaEntrega(item,nombreDia){
            this.horarios = item.horarios
            this.diaActivo = nombreDia
            this.entregaFecha = item.fecha
            this.entregaHorario = null
        },
        setMetodoPago(item){
            this.form.formaPago = item.tipo
            this.form.idMetodoPago = item.id_metodo_pago
        },
        accionUsuario(value){
            switch (value){
            case "Agregar Dirección":
            case "Cambiar Dirección":
                this.$refs.componentDirecciones.direcciones()
                break;
            case "Seguir Comprando":
                this.$router.push({name:'home.tienda'})
                break;
            default:
                break;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.advertencia{
        //height: px;
        display: flex;
        align-items: center;
        color: #f80404;
        justify-content: center;
        background-color: #ee7e7e;
        border-radius: 8px;
}
.disabled-div{
    pointer-events: none;
    opacity: 0.4;
}
.box-direction{
    box-shadow: 0px 3px 6px #0000000D;
    background-color: #FFFFFF;
    border-radius: 8px;
    .input-direction-mn{
        height: 40px;
        border-radius: 12px;
        border: 1px solid #DFE4E8;
    }
    .alert-cobertura{
        height: 30px;
        border-radius: 15px;
        color: #FF5806;
        border: 1px solid #FF5806;
    }
}
.box-convenio{
    box-shadow: 0px 3px 6px #0000000D;
    background-color: #FFFFFF;
    border-radius: 8px;
}
.box-forma-pago{
    box-shadow: 0px 3px 6px #0000000D;
    background-color: #FFFFFF;
    border-radius: 8px;
}
.box-delivery{
    box-shadow: 0px 3px 6px #0000000D;
    background-color: #FFFFFF;
    border-radius: 8px;
    .card-day{
        width: 60px;
        height: 49px;
        color: #707070;
        border-radius: 12px;
        border: 1px solid #DBDBDB;
        &-actual{
            @extend .card-day;
            background-color: #28D07B;
        }
    }
    .box-shedule{
		width: 65px;
		height: 50px;
		border-radius: 12px;
    	&-alternative{
			width: 94px;
			height: 49px;
			border-radius: 12px;
        }
    }
}
.bg-green{
    background-color: #28D07B;
}

.row-hours{
        height: 34px;
        align-items: center;
        color: #637381;
        &-active{
            @extend .row-hours;
            border: 1px solid #29D884;
            color: #000000;
        }
        .check-mn{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #637381;
            position: relative;
            .inside{
                display: none;
                position: absolute;
                left: 18%;
                top: 17%;
                background-color: #29D884;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }
            &-active{
                @extend .check-mn;
                border: 1px solid #29D884;
                .inside{
                    display: block;
                }
            }
        }
    }

@media (min-width: 300px) and (max-width: 985px){
    section.custom-scroll.overflow-auto{
        height: calc(100vh - 150px) !important;
    }
}
</style>