<template>
    <section class="px-4 pt-4 custom-scroll overflow-auto" style="height:calc(100vh - 85px)">
        <div class="row a-center h-100 mx-0 j-center">
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 px-0">
                <div class="row mx-0 j-center">
                    <img :src="funImagenGeneral(25)" width="194" height="194" />
                </div>
                <p class="text-genial text-center text-orange font-omnes f-40 mt-xl-5 mt-lg-3 mt-md-4 mt-sm-3 mt-2 mt-2">
                    Genial
                </p>
                <p class="text-general text-center f-15">
                    Tu pedido ha sido creado <span class="f-600">No. {{ pedido.id }}</span> 
                </p>
                <p v-show="pedido.entrega_fecha" class="text-general text-center f-15 mt-2">
                    Llegará el <span class="f-600">{{ pedido.entrega_fecha | helper-fecha('DD/MM/YYYY') }}</span> 
                </p>
                <p v-show="pedido.entrega_fecha" class="text-general text-center f-15 mt-2">
                    despues de las <span class="f-600">{{ pedido.entrega_horario }}:00 Horas </span> 
                </p>
                <p class="text-general text-center f-15 mt-4">
                    Valor del pedido <br />
                    <span class="f-600 f-20">
                        {{ convertMoney(pedido.total_pagar) }}
                    </span>
                </p>
                <div class="row mx-0 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3 mt-2 mt-2 mb-md-2">
                    <div class="col-6 px-2">
                        <div class="btn-general" @click="$router.push({name:'home.tienda'})">
                            Regresar a la Tienda
                        </div>
                    </div>
                    <div class="col-6 px-2">
                        <div class="btn-outline" @click="$router.push({name:'pedidos.ver',params:{idPedido:pedido.id}})">
                            Ir al pedido
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-xl-1 mt-lg-1 mt-md-4 mt-sm-2 mt-2 mt-1 mb-md-2 justify-content-center">
                    <div v-if="pedido.pagarLineaMp" class="col-6 px-2 my-2">
                        <div class="btn-general" @click="pagarMercadoPago">
                            Pagar en Linea
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Carrito from '~/services/carrito/carrito'
export default {
    data(){
        return {

        }
    },
    computed:{
        pedido(){
            return this.$route.params.data
        }
    },
    methods: {
        async pagarMercadoPago(){
            try {
                const payload = {
                    total:this.$route.params.data.total_pagar,
                    idPedido: this.$route.params.data.id
                }
                const data  = await Carrito.postPagarMercadoPago(payload)
                const url = data?.data?.data?.url ?? null
                if (url != null) window.location.href = url
                else this.notificacion('error', 'Ha ocurrido un error al generar el pago', 'error')
            } catch (error){
                return this.errorCatch(error);
            }
        }
    },
}
</script>